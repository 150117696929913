
import React from 'react'
import Carousel  from 'react-bootstrap/Carousel'

import gall1 from '../assets/gall1.png'
import gall2 from '../assets/gall2.png'
import gall3 from '../assets/gall3.png'
import gall6 from '../assets/gall6.png'
import gall7 from '../assets/gall7.png'
import gall8 from '../assets/gall8.png'
import bann from '../assets/bannernew.png'
import bann1 from '../assets/bannernew1.png'
import bann2 from '../assets/bannernew5.png'
import jan1 from '../assets/Jan2025_1.jpeg'
import jan2 from '../assets/Jan2025_2.jpeg'
import jan3 from '../assets/Jan2025_3.jpeg'
import jan4 from '../assets/Jan2025_4.jpeg'


function Gallery() {
  return (
    <>
    <div className='container-fluid py-3'>
      <h5 className='titlesec'>Image Gallery</h5>
     
     <div className='row gy-3 mt-3'>
      <div className='col-sm-12'>
        <h5 className='fw-bold'>January 2025</h5>
      </div>
      <div className='col-sm-3'>
        <img src={jan1} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={jan2} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={jan3} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={jan4} className='img w-100'/>
      </div>
     </div>

     <div className='row gy-3 mt-3'>
      <div className='col-sm-12'>
        <h5 className='fw-bold'>2024 and 2023</h5>
      </div>
      <div className='col-sm-3'>
        <img src={gall1} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={gall2} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={gall3} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={gall6} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={gall7} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={gall8} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={bann} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={bann1} className='img w-100'/>
      </div>
      <div className='col-sm-3'>
        <img src={bann2} className='img w-100'/>
      </div>
     </div>



      {/* <div className='row'>
    <Carousel>
       
    <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann2}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann1}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall1}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall2}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall3}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall6}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall7}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall8}
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  
      </div> */}
    </div>
    </>
  )
}

export default Gallery